const loggedRequest = (url, options) => {
	const nonce = window.wpApiSettings?.nonce;
	return fetch(
		url,
		(options = {
			...options,
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				"x-wp-nonce": nonce,
				...options?.headers,
			},
		})
	);
};

const notLoggedRequest = (url, options) => {
	return fetch(url, options);
};
export { loggedRequest, notLoggedRequest };
