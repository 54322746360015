import Content from "./container/Content/Content";
import Sidebar from "./components/Sidebar";
import sections from "./container/Content/sections";
import { useUser } from "./providers/UserProvider";
import { useTranslation } from "react-i18next";
import { Alert, Spinner } from "flowbite-react";

const App = () => {
	const { user, authLoading, authError } = useUser();
	const { t } = useTranslation();
	return (
		<div
			className={`bg-gray-100 min-h-full text-gray-900 tracking-wider leading-normal flex flex-col justify-${
				authLoading ? "center" : "start"
			}`}
		>
			{!!user && !authLoading && (
				<div className="container w-full flex flex-wrap mx-auto px-2 pt-8">
					<div className="w-full lg:w-1/5 px-6 text-xl text-gray-800 leading-normal">
						<Sidebar
							menuList={sections?.map((section) => ({
								id: section.id,
								label: section.title,
							}))}
							selectedMenuId={sections[1].id}
						/>
					</div>
					<Content />
				</div>
			)}
			<div className="flex flex-col items-center gap-6">
				{authLoading && (
					<div className="flex flex-col items-center gap-6">
						<span className="text-gray-700">{t("common.loading")}</span>
						<Spinner color="warning" aria-label="Loading progress" size="xl" />
					</div>
				)}
				{!!authError && !authLoading && (
					<Alert color="warning">
						<strong className="font-bold">{t("errors.error")}:</strong>
						<span className="block sm:inline">
							{t("auth.errors.notLogged")}
						</span>
					</Alert>
				)}
			</div>
		</div>
	);
};

export default App;
