import PersonalInfo from "./components/PersonalInfo";

const sections = [
	{
		id: "home",
		title: "sections.home.title",
		component: null,
	},
	{
		id: "personal-information",
		title: "sections.personalInfo.title",
		component: PersonalInfo,
		fields: {
			gender: {
				options: [
					{
						key: "sections.personalInfo.fields.gender.options.male",
						value: "male",
					},
					{
						key: "sections.personalInfo.fields.gender.options.female",
						value: "female",
					},
					{
						key: "sections.personalInfo.fields.gender.options.other",
						value: "other",
					},
				],
			},
			language: {
				options: [
					{
						key: "sections.personalInfo.fields.language.options.it",
						value: "it",
					},
					{
						key: "sections.personalInfo.fields.language.options.en",
						value: "en",
					},
				],
			},
		},
	},
	{
		id: "documents",
		title: "sections.documents.title",
		component: null,
	},
];

export default sections;
