import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locales from "./locales";

i18n.use(initReactI18next).init({
	resources: {
		en: { translation: locales.en },
		it: { translation: locales.it },
	},
	lng: "it", // Default language
	fallbackLng: "en", // Fallback language
	interpolation: {
		escapeValue: false, // React already does escaping
	},
});

export default i18n;
