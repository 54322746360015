import { useTranslation } from "react-i18next";
import useCountries from "../../../hooks/useCountries";
import { Controller, useForm } from "react-hook-form";
import Subtitle from "../../../components/Subtitle";
import { Alert, Datepicker, Spinner } from "flowbite-react";
import { loggedRequest } from "../../../utils/request";
import config from "../../../config";
import { useState } from "react";

const PersonalInfo = ({ section, defaultValues = {} }) => {
	const { t } = useTranslation();
	const [savedPersonalInfoStatus, setSavedPersonalInfoStatus] = useState({
		loading: false,
		status: null,
	});
	const { register, handleSubmit, formState, control } = useForm({
		defaultValues,
	});
	const { countries, error: getCountriesError } = useCountries();

	const savePersonalInfo = (data) => {
		const endpoint = `${config.environment.endpoint}/custom/v1/update-user-meta/`;
		setSavedPersonalInfoStatus({ ...savePersonalInfo, loading: true });
		loggedRequest(endpoint, {
			method: "POST",
			body: JSON.stringify(data),
		})
			.then((response) => {
				console.log(response);
				setSavedPersonalInfoStatus({ status: response.status, loading: false });
			})
			.catch((error) => {
				console.log(error);
				setSavedPersonalInfoStatus({ status: error.status, loading: false });
			});
	};

	return (
		<div className="flex flex-wrap -mx-3">
			<form
				className="w-full md:pb-1 gap-4"
				onSubmit={handleSubmit(savePersonalInfo)}
			>
				{/** Informazioni di base */}

				<Subtitle label={t("sections.personalInfo.subtitles.baseInfo")} />
				<div className="flex flex-wrap -mx-3 mb-6 gap-2">
					{/** Informazioni di base */}
					<div className="w-full lg:w-auto px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-first-name"
						>
							{t("sections.personalInfo.fields.firstName.label")}
						</label>
						<input
							className="cursor-not-allowed appearance-none block w-full pointer-events-none opacity-50  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							name="first_name"
							type="text"
							disabled={true}
							placeholder={t(
								"sections.personalInfo.fields.firstName.placeholder"
							)}
							{...register("first_name")}
						/>
					</div>
					<div className="w-full lg:w-auto px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-last-name"
						>
							{t("sections.personalInfo.fields.lastName.label")}
						</label>
						<input
							name="last_name"
							className="cursor-not-allowed appearance-none block w-full pointer-events-none opacity-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-last-name"
							disabled={true}
							type="text"
							placeholder={t(
								"sections.personalInfo.fields.lastName.placeholder"
							)}
							{...register("last_name")}
						/>
					</div>
					<div className="w-full lg:w-auto px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-gender"
						>
							{t("sections.personalInfo.fields.gender.label")}
						</label>
						<div className="relative">
							<select
								className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
								id="grid-gender"
								name="gender"
								{...register("gender")}
							>
								{section.fields.gender.options.map((option) => (
									<option key={option.key} value={option.value}>
										{t(option.key)}
									</option>
								))}
							</select>
						</div>
					</div>
					{/** Informazioni di nascita */}
					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-birthCity"
						>
							{t("sections.personalInfo.fields.birthCity.label")}
						</label>
						<input
							className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-city"
							name="birthCity"
							type="text"
							placeholder={t(
								"sections.personalInfo.fields.birthCity.placeholder"
							)}
							{...register("birthCity")}
						/>
					</div>
					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-birthState"
						>
							{t("sections.personalInfo.fields.birthState.label")}
						</label>
						{!getCountriesError ? (
							<div className="relative">
								<Controller
									name="birthState"
									control={control}
									render={({ field }) => (
										<select
											className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-birthState"
											{...field}
										>
											{countries.map((country) => (
												<option key={country.value} value={country.value}>
													{country.label}
												</option>
											))}
										</select>
									)}
								/>
							</div>
						) : (
							<div
								className="bg-red-100 border border-red-400 text-red-700 px-4 text-sm py-3 rounded relative"
								role="alert"
							>
								<span className="block sm:inline">
									{t("sections.personalInfo.fields.address.state.error")}
								</span>
							</div>
						)}
					</div>
					<div className="w-full lg:w-auto px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-birthdate"
						>
							{t("sections.personalInfo.fields.birthdate.label")}
						</label>
						<Controller
							control={control}
							name="birthdate"
							render={({ field: { onChange } }) => (
								<Datepicker
									onSelectedDateChanged={onChange}
									showTodayButton={false}
									defaultDate={
										new Date(
											!!defaultValues.birthdate
												? defaultValues.birthdate
												: new Date()
										)
									}
								/>
							)}
						/>
					</div>
				</div>

				{/** Informazioni di residenza */}

				<Subtitle label={t("sections.personalInfo.subtitles.residence")} />
				<div className="flex flex-wrap -mx-3 mb-6 gap-2">
					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-city"
						>
							{t("sections.personalInfo.fields.address.city.label")}
						</label>
						<input
							className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-city"
							name="city"
							type="text"
							placeholder={t(
								"sections.personalInfo.fields.address.city.placeholder"
							)}
							{...register("city")}
						/>
					</div>
					<div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-state"
						>
							{t("sections.personalInfo.fields.address.state.label")}
						</label>
						{!getCountriesError ? (
							<div className="relative">
								<Controller
									name="state"
									control={control}
									render={({ field }) => (
										<select
											className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
											id="grid-state"
											{...field}
										>
											{countries.map((country) => (
												<option key={country.value} value={country.value}>
													{country.label}
												</option>
											))}
										</select>
									)}
								/>
							</div>
						) : (
							<div
								className="bg-red-100 border border-red-400 text-red-700 px-4 text-sm py-3 rounded relative"
								role="alert"
							>
								<span className="block sm:inline">
									{t("sections.personalInfo.fields.address.state.error")}
								</span>
							</div>
						)}
					</div>
					<div className="w-full md:w-auto px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-zip"
						>
							{t("sections.personalInfo.fields.address.zip.label")}
						</label>
						<input
							className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-zip"
							name="zip"
							type="text"
							placeholder={t(
								"sections.personalInfo.fields.address.zip.placeholder"
							)}
							{...register("zip")}
						/>
					</div>
					<div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-address"
						>
							{t("sections.personalInfo.fields.address.address.label")}
						</label>
						<input
							className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							id="grid-address"
							name="address"
							type="text"
							placeholder={t(
								"sections.personalInfo.fields.address.address.placeholder"
							)}
							{...register("address")}
						/>
					</div>
				</div>

				{/** Informazioni di contatto */}

				<Subtitle label={t("sections.personalInfo.subtitles.contacts")} />
				<div className="flex flex-wrap -mx-3 gap-2 mb-6">
					<div className="w-full lg:w-1/3 px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-email"
						>
							{t("sections.personalInfo.fields.email.label")}
						</label>
						<input
							className="cursor-not-allowed appearance-none block w-full  text-gray-700 pointer-events-none opacity-50 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							name="email"
							type="email"
							disabled={true}
							placeholder={t("sections.personalInfo.fields.email.placeholder")}
							{...register("email")}
						/>
					</div>
					<div className="w-full lg:w-auto px-3">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="grid-phone"
						>
							{t("sections.personalInfo.fields.phone.label")}
						</label>
						<input
							className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
							name="phone"
							type="tel"
							placeholder={t("sections.personalInfo.fields.phone.placeholder")}
							{...register("phone")}
						/>
					</div>
				</div>
				<div className="flex flex-col items-center gap-4">
					<button
						type="submit"
						className="bg-gold w-1/3 disabled:bg-grey text-white font-bold py-2 px-4 rounded"
						disabled={!formState.isDirty || savedPersonalInfoStatus.loading}
					>
						{savedPersonalInfoStatus.loading ? (
							<Spinner className="h-6 w-6 animate-spin" color="gray" />
						) : (
							t("sections.personalInfo.common.save")
						)}
					</button>

					{savedPersonalInfoStatus.status && (
						<Alert
							color={
								savedPersonalInfoStatus.status >= 400 ? "failure" : "success"
							}
						>
							{t(
								`sections.personalInfo.common.${
									savedPersonalInfoStatus.status >= 400
										? "savedError"
										: "savedSuccess"
								}`
							)}
						</Alert>
					)}
				</div>
			</form>
		</div>
	);
};

export default PersonalInfo;
