import { createContext, useContext, useEffect, useState } from "react";
import useWPAPI from "../hooks/useWPAPI";
const UserContext = createContext();

const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [authError, setAuthError] = useState(null);

	const wp = useWPAPI();
	useEffect(() => {
		wp.users()
			.me()
			.then((data) => {
				setUser(data);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setAuthError(err);
				setLoading(false);
			});
	}, []);

	return (
		<UserContext.Provider value={{ user, authError, authLoading: loading }}>
			{children}
		</UserContext.Provider>
	);
};

const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser should be used in a UserProvider");
	}
	return context;
};

export { UserProvider, useUser };
