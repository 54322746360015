import WPAPI from "wpapi";
import config from "../config";

const useWPAPI = () => {
	const wpApiSettings = window.wpApiSettings || {};
	const nonce = wpApiSettings.nonce;
	return new WPAPI({
		endpoint: config.environment.endpoint,
		nonce: nonce,
		credentials: "include",
	});
};

export default useWPAPI;
