import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";

const Sidebar = ({ menuList, selectedMenuId }) => {
	const { t } = useTranslation();
	const [activeMenu, setActiveMenu] = useState(selectedMenuId);

	useEffect(() => {
		if (activeMenu === "home")
			window.location.href = config.environment.siteUrl;
	}, [activeMenu]);

	return (
		<div
			className="w-full sticky inset-0 top-40 hidden max-h-64 lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
			id="menu-content"
		>
			<p className="text-base font-bold py-2 lg:pb-6 text-gray-700">Menu</p>
			<ul className="list-reset py-2 md:py-0">
				{menuList.map((menu) => (
					<li
						key={menu.id}
						className={`py-1 md:my-2
 ${activeMenu === menu.id ? "font-bold border-l-4 border-gold" : ""}`}
						onClick={(e) => {
							e.preventDefault();
							setActiveMenu(menu.id);
						}}
					>
						<a
							href={menu.id}
							className="block pl-4 align-middle text-gray-700 no-underline hover:text-gold"
						>
							<span className="pb-1 md:pb-0 text-sm">{t(menu.label)}</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Sidebar;
