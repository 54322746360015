import { useState, useEffect } from "react";

const useCountries = () => {
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const apiUrl = "https://restcountries.com/v3.1/all?sort=name";

		fetch(apiUrl)
			.then((response) => {
				if (!response.ok) {
					throw new Error(
						`HTTP error getting countries! Status: ${response.status}`
					);
				}
				return response.json();
			})
			.then((data) => {
				const customCountries = data.map((country) => ({
					label: country.name.common,
					value: country.cca2,
				}));
				const sortedCountries = customCountries.sort((a, b) =>
					a.label.localeCompare(b.label)
				);

				setCountries(sortedCountries);
				setLoading(false);
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			});
	}, []); // Empty dependency array ensures that the effect runs only once

	return { countries, loading, error };
};

export default useCountries;
