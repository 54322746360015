import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n"; // Import translations manager
import { UserProvider } from "./providers/UserProvider";

const root = ReactDOM.createRoot(document.getElementById("gibs-profile-page"));
root.render(
	<UserProvider>
		<App />
	</UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
