import { useTranslation } from "react-i18next";

import sections from "./sections";
import { useUser } from "../../providers/UserProvider";

const Content = () => {
	const { t } = useTranslation();
	const { user } = useUser();

	return (
		<section className="w-full lg:w-4/5 flex flex-col justify-center">
			<>
				<h1 className="flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl">
					{t("welcome", { name: user.first_name })}
				</h1>
				<div className="flex flex-col gap-4">
					{sections.map((section) => {
						const Section = section.component;
						return (
							Section && (
								<div key={t(section.id)}>
									<h2
										id={t(section.id)}
										className="font-sans font-bold break-normal text-gray-700 px-2 text-xl"
									>
										{t(section.title)}
									</h2>

									<div className="p-8 mt-6 lg:mt-2 leading-normal rounded shadow bg-white">
										{section.component && (
											<Section
												section={section}
												user={user}
												defaultValues={{ ...user }}
											/>
										)}
									</div>
								</div>
							)
						);
					})}
					<hr className="bg-gray-300 my-12" />
				</div>
			</>
		</section>
	);
};

export default Content;
